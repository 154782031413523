import React from 'react'
import Layout from '../components/Layout'
import round from "../assets/ROUND.png"
import close from "../assets/X.png"

export default function Alert(props) {
    return (
        <div style={{ position: "relative" }}>
            <div style={{ filter: "blur(3px)", position: "absolute" }} >
                <Layout display="blur"></Layout>
            </div>
            
            <div className="cfm-alert-contaier-image">
                <div className="cfm-alert-box-container">

                    <div className="cfm-alert-box-text">
                        <img src={close} className="cfm-alert-image-close" alt="clubfm-alert" style={{cursor:"pointer"}} onClick={() => props.cancelClick("howtoparticipate")}></img>

                        {props.children}
                    </div>
                    <img src={round} className="cfm-alert-box-image" alt="clubfm-alert-img"></img>

                </div>
            </div>
        </div>
    )
}
