import React, {  useEffect, useState } from 'react'
import logo from "../assets/LOGO.png"
import tovino from "../assets/TOVINO.png"
import playlist from "../assets/playlist-full.png"
import mobile from "../assets/music-mobile.png"

import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from '../constants'
import ErrorPage from './ErrorPage'

export default function Home2() {

    const history = useHistory()

    const [errorMsg, setErrorMsg] = useState({ flag: false, message: "", status: "" })

    useEffect(() => {

        axios.get(`${API_URL}/getCurrentWeek`)
            .then(res => localStorage.setItem("currentWeek", res?.data?.data[0]?.week))
            .catch(err => console.log(err))

        axios.get(`${API_URL}/getContestStatus`)
            .then(res => {
              
                if (res?.data?.status === "success") {
                    localStorage.setItem("contestStatus", res?.data?.message)

                } else if (res?.data?.status === "failure") {
                    setErrorMsg({ flag: true, message: res?.data?.message, status: res?.data?.status })
                }

            })
            .catch(err => console.log(err))
    }, [])

    const cancelClick = () => {
        history.push("/")
    }

    return (
        <div>
            {/* Check the contest end or not */}
            {errorMsg.flag ? <ErrorPage status={errorMsg.status} errorMsg={errorMsg.message} callbackErrorMessage={() => cancelClick()}></ErrorPage> :

                // Home page
                <div className="container">
                    <div className="cfm-box">
                        <div className="cfm-logo-new-container">
                            <div className="cfm-logo-img">
                                <img src={logo}  alt="clubfm-vote-logo"></img>
                                <img src={playlist} className="cfm-playlist-img"  alt="clubfm-playlist"></img>
                            </div>
                            <div className="cfm-logo-text">
                                <p className="cfm-white">Vote for <span className="cfm-brand">U</span>r favorite song and win exciting prizes</p>
                            </div>
                        </div>
                    </div>
                    <div className="cfm-box">
                        <div className="cfm-music-new-container">
                            <img src={mobile}  alt="clubfm-mobile-image"></img>
                            <Link to="/how-to-participate"><button>PROCEED</button></Link>
                        </div>


                    </div>
                    <div className="">
                        <div className="cfm-bottom-new-container" style={{ marginTop: "20%" }}>
                            <img src={tovino}  alt="clubfm-tovino"></img>

                        </div>
                    </div>

                </div >
            }
        </div>
    )
}
