import React from 'react'

import logo from "../../src/assets/LOGO.png"
import tovino from "../../src/assets/TOVINO.png"
import playlist from "../../src/assets/playlist-full.png"
import fb from "../assets/social-icons/fb.png"
import insta from "../assets/social-icons/insta.png"
import yt from "../assets/social-icons/yt.png"
import twitter from "../assets/social-icons/twitter.png"
import { useHistory } from 'react-router'


export default function Layout(props) {

    let history = useHistory()

    return (
        <div style={{
            filter: props.display === "blur" && "blur(3px)"
        }}>
            <div className="cfm-page" style={{ height: props.display === "general" ? "100%" : "100%" }}>
                < div className="container" style={{ height: props.display === "general" ? "100%" : "100%" }}>

                    <div className="cfm-logo-container" style={{ justifyContent: "center" }}>
                        <img src={logo} className="cfm-layout-vote-image" onClick={() => history.push("/")} style={{ cursor: "pointer" }} alt="club-fm-image"></img>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <img src={playlist} className="cfm-layout-path-full-container" alt="club-fm-image"></img>
                    
                    </div>
                    {props?.display === "blur" ?
                        <div style={{ height: "35vh" }}>

                        </div> : ""}

                    {props.children}
                    {props.display === "blur" ?
                        <div style={{ height: "23%" }}>
                            <img src="" alt="no-image"></img>
                        </div> : <div>                            <img src="" alt="no-image"></img>
                        </div>}
                    {props.display === "thanks" ? <div>
                        <div className={props.display === "thanks" ? "cfm-bottom-image" : "cfm-bottom-image"} style={{ width: "100%", textAlign: "center", display: "flex", justifyContent: "center" }}>
                            <div style={{ width: "48%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center" }}>
                                <div style={{width:"11rem"}}>
                                    <h3 id="thanks-tune" style={{ color: "white", fontWeight: "500"}}>Tune in to <span className="cfm-vote-text-brand" style={{ fontWeight: "600" }}>CLUB FM</span> to know if <span className="cfm-vote-text-brand" style={{ fontWeight: "600" }}>U</span>r song is in the top list</h3>
                                </div>

                                <p style={{ width: "90%", color: "white",  marginBottom:"0%" }}>Follow to know</p>
                                <p style={{ width: "90%", color: "white",  marginTop:"0%" }}>winner updates</p>
                                <div>
                                    <ul style={{ listStyleType: "none", display: "flex", paddingLeft: "15%", width: "70%" }}>
                                        <li>
                                            {/* <RiFacebookCircleLine size={40} className="fb"></RiFacebookCircleLine> */}
                                            <a href="https://www.facebook.com/clubfmkerala/" target="_blank">
                                                <img src={fb} style={{ width: "60%" }} className="fb" alt="club-fm-fb"></img></a>
                                        </li>
                                        <li><a href="https://www.instagram.com/clubfmkerala/" target="_blank">
                                            <img src={insta} style={{ width: "60%" }} className="insta" alt="club-fm-insta"></img></a></li>
                                        <li><a href="https://www.youtube.com/channel/UCiCzK8JvuBSATbBa4FFaf7w" target="_blank">
                                            <img src={yt} style={{ width: "60%" }} className="yt" alt="club-fm-yt"></img></a></li>
                                        <li><a href="https://twitter.com/clubfmkerala" target="_blank">
                                            <img src={twitter} style={{ width: "60%" }} className="twitter" alt="club-fm-twitter"></img></a></li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> :
                        <div className="cfm-bottom-new-container" style={{marginTop:"10%", display: "flex", alignItems: "flex-end" }}>
                            <img src={tovino} ></img>
                        </div>}
                </div>
            </div>
        </div>
    )
}
