import React from 'react'
import { useHistory } from 'react-router'
import Alert from './Alert'

export default function ErrorPage(props) {



    let history = useHistory()

    const cancelClick = () => {
    
        props.callbackErrorMessage()
    }
    return (
        <div>
            <Alert cancelClick={() => cancelClick()}>
                <div className="cfm-errorpage-container">
                    {props?.status === "failure" ?

                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "90%" }}>
                            {props?.errorMsg.includes("Contest") ?
                                <div>
                                    <p style={{ width: "22rem", color: "#fff", marginBottom: "2px" }}>{props?.errorMsg}</p>
                                </div>
                                :
                                <div>
                                    <p style={{ width: "22rem", color: "#fff000", marginBottom: "2px" }}><span style={{ fontWeight: "500" }}>U</span> have already participated !</p>
                                    <p style={{ marginTop: "0px" }}>Come back next week</p>
                                </div>
                            }
                        </div>
                        :
                        <p style={{ color: "white" }}>{props.errorMsg}</p>}
                </div>
            </Alert>
        </div>
    )
}
