import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import PlayListComponent from '../components/PlayListComponent'


import { API_URL } from "../../src/constants"
import ErrorPage from './ErrorPage'

export default function Playlist() {

    const [songList, setSongList] = useState([])
    const [selectedSong, setSelectedSong] = useState(0)

    const [action, setAction] = useState("")

    const [errorMsg, setErrorMsg] = useState({ flag: false, message: "" })


    let history = useHistory()

    useEffect(() => {
        let currentWeek = JSON.parse(localStorage.getItem("currentWeek"))

        axios.get(`${API_URL}/getPlaylist`)
            .then((res) => {
              
                let filterSongs = res?.data.filter(e => parseInt(e.week) === parseInt(currentWeek))
               
                setSongList(filterSongs)

            })
            .catch((err) => console.log(err))


    }, [])


    const onNextClick = () => {
        axios.get(`${API_URL}/getContestStatus`)
        .then(res => {
          
            if (res?.data?.status === "success") {
                localStorage.setItem("contestStatus", res?.data?.message)

            } else if (res?.data?.status === "failure") {
                setErrorMsg({ flag: true, message: res?.data?.message, status: res?.data?.status })
            }

        })
        .catch(err => console.log(err))
        if (action === "add") {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = yyyy + '-' + mm + '-' + dd;

            let userdata = localStorage.getItem("userData")
            let newUpdateuserdata = JSON.parse(userdata)
         
            if (selectedSong != 0) {
                let data = {
                    "id": newUpdateuserdata.user_id,
                    "song_id": selectedSong,
                    vote_date: today
                }
                

                axios.post(`${API_URL}/submitSong`, data)
                    .then(res => {
                        if (res.data.status === "failure") {
                            setErrorMsg({ flag: true, message: res.data.message, status: res.data.status })

                        } else {
                            history.push("/thanks")

                        }

                    })
                    .catch(err => console.log(err))
                  
            }
        } else {
            setErrorMsg({ flag: true, message: "Please select a song" })

        }

    }

    const selectedSongs = (id, action) => {
       
        setAction(action)
        setSelectedSong(id)
        let newdata = songList.map((item, index) => {
            if (item.song_id === id) {
                // item.selected = action === "add" ? true : false
                if (action === "add") {
                    item.selected = true

                } else {
                    item.selected = false
                }
            } else {
                item.selected = false
            }
            return item
        })
      

        setSongList(newdata)



    }

    const callbackCancel = ()=>{
        setErrorMsg({ flag: false, message: "" })
        // history.push("/")
    }

    return (
        <div>
            {errorMsg.flag ? <ErrorPage status={errorMsg.status} errorMsg={errorMsg.message} callbackErrorMessage={() => callbackCancel()}></ErrorPage> :
                <div className="cfm-page">
                    <div className="container" style={{ height: "100vh" }}>
                        <div className="cfm-playlist-container">
                            <div style={{ height: "100%", overflow: "auto" }}>
                                {songList.map((item, index) => (
                                    <PlayListComponent index={index} item={item} songSelect={selectedSongs}></PlayListComponent>

                                ))}
                            </div>

                        </div>
                        <div className="cfm-playlist-submit-container" style={{}}>
                            <div className="cfm-bottom-container" style={{ marginTop: "6%" }}>
                                <button className="cfm-bottom-button cfm-playlist-button" onClick={() => onNextClick()} >Submit</button>

                            </div>
                        </div>
                    </div>
                </div >}
        </div>
    )
}
