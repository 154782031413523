import React from 'react'
import Layout from '../components/Layout'



export default function Thanks() {


    // const [songList, setSongList] = useState([])
    // const [getTopFive, setGetTopFive] = useState([])


    // useEffect(() => {
    //     axios.get(`${API_URL}/getPlaylist`)
    //         .then((res) => {
    //             let sortedList = res.data.sort((a, b) => {
    //                 return b.vote - a.vote;
    //             });
    //             setSongList(sortedList.slice(0, 5))
    //         })
    //         .catch((err) => console.log(err))

    //     axios.get(`${API_URL}/getTopFive`)
    //         .then(res => setGetTopFive(res.data.data))
    //         .catch(err => console.log(err))
    // }, [])

    return (
        <div>

            <Layout display="thanks">
                <div style={{ padding:"20px", height:"25vh",display:"flex",justifyContent:"center",alignItems:"center" }}>

                <h3 style={{ color: "white", textAlign: "center",marginLeft:"35px",marginRight:"35px" }}>Thank you for participating in the Contest !</h3>
                

                </div>
            </Layout>

        </div>
    )
}
