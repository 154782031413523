import React from 'react'

import { RiCloseLine, RiAddLine } from "react-icons/ri";

import placeholderImage from "../assets/image-placeholder.png"

export default function PlayListComponent(props) {
    return (
        <div>
            <div>
                <div className="cfm-playlist-component-container" style={{ textAlign: props.type === "thanks" ? "center" : "", display: "flex", justifyContent: "space-between", paddingRight: "10px", padding: "2%", zIndex: "1", backgroundColor: props.item.selected && "#2b2b2b", cursor: props.type === "thanks" ? "" : "pointer" }}>
                    <div className="cfm-playlist-component-container-div" style={{}} onClick={() => props.type === "thanks" ? "" : props.songSelect(props.item.song_id, "add")}>
                        <img src={props?.item?.thumbnail ? process.env.PUBLIC_URL + "/uploads/" + props?.item?.thumbnail : placeholderImage} style={{ width: "16%", height: "100%" }} alt="clubfm-playlist"></img>
                        <div style={{ paddingLeft: "3%", color: "white",marginTop:"-5px" }}>
                            <h3 style={{ margin: "0", fontWeight: "500" }}>{props.item.song.charAt(0).toUpperCase() + props.item.song.slice(1)}</h3>
                            <p style={{ margin: "0", color: "#b2b2b2" }}>{props.item.album.charAt(0).toUpperCase() + props.item.album.slice(1)} - {props.item.year}</p>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        {props.item.selected ? <RiCloseLine className="cfm-playlist-close" onClick={() => props.type === "thanks" ? "" : props.songSelect(props.item.song_id, "remove")} color="#fff000" style={{ zIndex: "10" }}></RiCloseLine> :
                            props.type === "thanks" ? "" : <RiAddLine className="cfm-playlist-add" color="#fff000" onClick={() => props.type === "thanks" ? "" : props.songSelect(props.item.song_id, "add")} style={{ cursor: "pointer" }} />}
                    </div>

                </div>
            </div>
        </div>
    )
}
