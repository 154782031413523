import React from 'react'
import Alert from './Alert'
import { Link, useHistory } from 'react-router-dom'


export default function HowToParticipate() {


    let history = useHistory()

    const cancelClick = () => {

        history.push("/register")

    }

    return (
        <div>
            
            <Alert cancelClick={() => cancelClick()}>
                <h4>How To Participate</h4>
                <ul style={{ textAlign: "initial", color: "white",marginTop:"3%" }}>
                    <li> Choose <span style={{color:"#fff000",fontWeight:"500"}}>U</span>r one favorite song</li>
                    <li style={{paddingTop:"5px"}}>Click on Submit</li>
                    <li style={{paddingTop:"5px"}}>Top 5 chosen songs will be played on CLUB FM everyday</li>
                </ul>
                <Link to="/terms-and-conditions" style={{textDecoration:"none"}}>
                    <p className="cfm-alert-read">*Read T&C</p>

                </Link>

            </Alert>
        </div>
    )
}
