import React, { useEffect, useState } from "react";
import { RiArrowLeftLine } from "react-icons/ri";
import axios from "axios";
import { API_URL } from "../constants";

export default function TermsAndConditions() {
  const [termsOutput, setTermsOutput] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/getTerms`)
      .then((res) => {
        setTermsOutput(res?.data?.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const backClick = () => {
    window.history.back();
  };

  return (
    <div>
      <div className="cfm-page">
        <div
          className="container"
          style={{ height: "100vh", overflowX: "auto" }}
        >
          <div style={{ padding: "6%" }} className="cfm-container-terms">
            <h4
              className="cfm-vote-text-brand cfm-container-text-brand-h4"
              style={{
                marginTop: "0px",
                fontWeight: "500",
                borderBottom: " 1px solid #9e9e9e",
                paddingBottom: " 7px",
              }}
            >
              <span style={{ marginRight: "20px", cursor: "pointer" }}>
                <RiArrowLeftLine
                  className="cfm-vote-text-brand-icon-arrow"
                  onClick={() => backClick()}
                ></RiArrowLeftLine>
              </span>
              Terms & Conditions
            </h4>
            <ol className="cfm-terms-list">
              {termsOutput.map((item, index) => (
                <li key={item.id}>{item.terms}</li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
