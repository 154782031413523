import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'

export default function Nomatch() {

    let location = useLocation()
    let history = useHistory()

    useEffect(() => {
      
        history.push(`${location.pathname}`)
    }, {})

    return (
        <div>
            <div className="cfm-page" style={{ height: "100vh", color: "white", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <h1>404</h1>
                    <p style={{ paddingLeft: "20px" }}>Not Found</p>
                </div>
                <div>
                    <p>Page not found !!!</p>
                </div>
            </div>
        </div>
    )
}
