import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../components/Layout";
import ErrorPage from "./ErrorPage";

import { API_URL } from "../../src/constants";

export default function Register() {
  let history = useHistory();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [errorMsg, setErrorMsg] = useState({
    flag: false,
    message: "",
    status: "",
  });

  const [validateEmailError, setValidateEmailError] = useState(false);

  const [validateContact, setValidateContact] = useState(false);

  const [validateName, setValidateName] = useState(false);

  //Register submission
  const submitClick = (e) => {
    e.preventDefault();
    // history.push("/playlist")
    let emailValidate = ValidateEmail(email);

    if (name) {
      setValidateName(false);
    } else {
      setValidateName(true);
    }

    if (contact) {
      setValidateContact(false);
    } else {
      setValidateContact(true);
    }

    if (emailValidate === false) {
      setValidateEmailError(true);
    } else {
      setValidateEmailError(false);

      if (name && email && contact) {
        setValidateEmailError(false);

        // setErrorMsg({ flag: true, message: "Already Registerd" })

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy + "-" + mm + "-" + dd;
        let data = {
          name: name,
          email: email,
          contact_no: contact,
          vote_date: today,
        };
        axios
          .post(`${API_URL}/register`, data)
          .then((res) => {
            if (res.data.status === "success") {
              localStorage.setItem("userData", JSON.stringify(res.data));
              history.push("/playlist");
            } else if (res.data.status === "failure") {
              setErrorMsg({
                flag: true,
                message: res.data.message,
                status: res.data.status,
              });
            } else if (res.data.status === "winner") {
              setErrorMsg({
                flag: true,
                message: res.data.message,
                status: res.data.status,
              });
            }
          })
          .catch((err) => console.log(err));

        axios
          .get(`${API_URL}/getContestStatus`)
          .then((res) => {
            if (res?.data?.status === "success") {
              localStorage.setItem("contestStatus", res?.data?.message);
            } else if (res?.data?.status === "failure") {
              setErrorMsg({
                flag: true,
                message: res?.data?.message,
                status: res?.data?.status,
              });
            }
          })
          .catch((err) => console.log(err));
      } else {
      }
    }
  };
  const ValidateEmail = (email) => {
    if (
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        email
      )
    ) {
      return true;
    }
    return false;
  };

  const checkValidateEmail = (email) => {
    setEmail(email);
    let emailValidate = ValidateEmail(email);

    if (emailValidate) {
      setValidateEmailError(false);
    } else {
      setValidateEmailError(true);
    }
  };

  const chackContactNo = (no) => {
    setContact(no);

    if (no) {
      setValidateContact(false);
    } else {
      setValidateContact(true);
    }
  };

  const checkValidateName = (name) => {
    setName(name);

    if (name) {
      setValidateName(false);
    } else {
      setValidateName(true);
    }
  };

  // Callback of ErrorPage
  const cancelClick = () => {
    setErrorMsg({ flag: false, message: "" });
    setName("");
    setEmail("");
    setContact("");
  };

  const validate=(evt)=> {
    var theEvent = evt || window.event;
  
    // Handle paste
    if (theEvent.type === 'paste') {
        key = evt.clipboardData.getData('text/plain');
    } else {
    // Handle key press
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\+/;
    if( !regex.test(key) ) {
      theEvent.returnValue = false;
      if(theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  return (
    <div>
      {errorMsg.flag ? (
        <ErrorPage
          status={errorMsg.status}
          errorMsg={errorMsg.message}
          callbackErrorMessage={() => cancelClick()}
        ></ErrorPage>
      ) : (
        <Layout display="general">
          <div className="cfm-register-container">
            <form>
              <div className="cfm-register-form">
                <label>Name</label>
                <br></br>
                <input
                  type="text"
                  required
                  onChange={(e) => checkValidateName(e.target.value)}
                  placeholder="Please Enter Name"
                ></input>
                {validateName ? (
                  <span style={{ color: "red", fontSize: "10px" }}>
                    * Please enter Name
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="cfm-register-form">
                <label>E mail</label>
                <br></br>
                <input
                  type="email"
                  required
                  onChange={(e) => checkValidateEmail(e.target.value)}
                  placeholder="Please Enter Email"
                ></input>
                {validateEmailError ? (
                  <span style={{ color: "red", fontSize: "10px" }}>
                    * Please check Email format
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="cfm-register-form">
                <label>Contact No</label>
                <br></br>
                <input
                  type="text"
                  required
                  maxLength={15}
                  onKeyPress={(e)=>validate(e)}
                  onChange={(e) => chackContactNo(e.target.value)}
                  placeholder="Please Enter Contact No"
                ></input>
                {validateContact ? (
                  <span style={{ color: "red", fontSize: "10px" }}>
                    * Please enter Contact no
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div
                className="cfm-bottom-container"
                style={{ marginTop: "10%" }}
              >
                {/* <Link to="/playlist"> */}
                <button
                  className="cfm-bottom-button"
                  type="submit"
                  onClick={(e) => submitClick(e)}
                >
                  SELECT SONG
                </button>
                {/* </Link> */}
              </div>
            </form>
          </div>
        </Layout>
      )}
    </div>
  );
}
