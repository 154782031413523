import React from 'react'
import Home from './pages/Home'

import "./styles/styles.scss"
import "./styles/styles-media.scss"

import Register from './pages/Register'
import Playlist from './pages/Playlist'
import Thanks from './pages/Thanks'
import TermsAndConditions from './pages/TermsAndConditions'
import {
  Route, BrowserRouter as Router, Switch,
} from 'react-router-dom'
import HowToParticipate from './pages/HowToParticipate'
import Home2 from './pages/Home2'
import Nomatch from './pages/Nomatch'

export default function App() {

  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home2></Home2>
          </Route>

          <Route path="/playlist">
            <Playlist></Playlist>
          </Route>
          <Route path="/register">
            <Register></Register>
          </Route>
          <Route path="/how-to-participate">
            <HowToParticipate></HowToParticipate>
          </Route>

          <Route path="/terms-and-conditions">
            <TermsAndConditions></TermsAndConditions>
          </Route>
          <Route path="/thanks">
            <Thanks></Thanks>
          </Route>
          <Route path="*">
            <Nomatch></Nomatch>
          </Route>
        </Switch>
      </Router>

    </div>
  )
}
